import { createContext, useContext, useMemo } from 'react';

import LoansService from 'services/PlatformLoans/LoansService';
import { storageKeys } from 'store/storage-keys';
import { storage } from 'utils/storage';
import { useStore } from 'zstore';

interface LoanDetailsContextProps {
  fetchLoanDetails: (uuid?: string) => Promise<any>;
}

export const LoanDetailsContext = createContext({} as LoanDetailsContextProps);

export const LoanDetailsProvider = ({ children = null }) => {
  const { setLoanDetails } = useStore();

  const fetchLoanDetails = async (uuid) => {
    try {
      storage.setItem(storageKeys.loanUid, String(uuid));

      const { data } = await LoansService.getLoanByUid(uuid);

      setLoanDetails(data.data);
    } catch {
      throw Error();
    }
  };

  const value = { fetchLoanDetails };

  return (
    <LoanDetailsContext.Provider value={value}>
      {children}
    </LoanDetailsContext.Provider>
  );
};

export const useLoanDetails = () => {
  const ctx = useContext(LoanDetailsContext);

  return useMemo(() => ctx, [ctx]);
};
