import { cloneElement } from 'react';

import { AuthProvider } from 'contexts/AuthContext';
import { BorrowerProvider } from 'contexts/BorrowerContext';
import { CreateAccountProvider } from 'contexts/CreateAccountContext';
import { DBTrackingProvider } from 'contexts/DBTrackingContext';
import { LoanDetailsProvider } from 'contexts/LoanDetailsContext';
import { MockProvider } from 'contexts/MockContext';
import { ModalBannerProvider } from 'contexts/ModalBanner';
import { ModalProvider } from 'contexts/ModalContext';
import { MultiThemeProvider } from 'contexts/MultiThemeProvider';
import { NavigationProvider } from 'contexts/NavigationContext';
import { PendenciesProvider } from 'contexts/PendenciesContext';
import { RequirementsProvider } from 'contexts/RequirementsContext';

const providers = [
  <MultiThemeProvider />,
  <AuthProvider />,
  <ModalProvider />,
  <ModalBannerProvider />,
  <MockProvider />,
  <CreateAccountProvider />,
  <BorrowerProvider />,
  <NavigationProvider />,
  <RequirementsProvider />,
  <PendenciesProvider />,
  <LoanDetailsProvider />,
  <DBTrackingProvider />,
];

const Store = ({ children: initial }) =>
  providers.reduce(
    (children, parent) => cloneElement(parent, { children }),
    initial,
  );

export { Store };
