import { useState, useEffect } from 'react';
import { FiMessageCircle } from 'react-icons/fi';

import { useRouter } from 'hooks/useRouter';
import { useStore } from 'zstore';

import * as S from './styles';

export default function ButtonChat() {
  const { location } = useRouter();
  const { loansData } = useStore();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const conditionalPages = ['/dashboard', '/dashboard/'];

    if (conditionalPages.includes(location.pathname) && loansData) {
      if (loansData.loans?.[0]?.status === 'lent') {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    } else {
      setShowButton(false);
    }
  }, [location.pathname, loansData?.loans && loansData?.loans?.[0]?.status]);

  return (
    <>
      {showButton && (
        <S.Container>
          <a
            href="http://ws.parla.com.br/noverde"
            target="_blank"
            rel="noreferrer"
          >
            <div>
              <FiMessageCircle size={22} />
            </div>{' '}
            Chat
          </a>
        </S.Container>
      )}
    </>
  );
}
