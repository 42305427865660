import { Button } from 'components/Button';
import styled from 'styled-components';

export const Container = styled(Button)`
  width: 106.69px;
  min-height: 45.97px;
  height: 45.97px;
  padding: 10px 20px;
  border-radius: 999rem;

  background-color: #25bb89 !important;
  color: #ffffff !important;
  fill: #ffffff !important;

  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 99999;

  div {
    width: 22px;
    height: 22px;
  }

  a {
    letter-spacing: 0.6;
    font-size: 1rem;
    text-transform: capitalize;

    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
`;
