import React, { useState } from 'react';

import iconFacebook from 'assets/img/icon-facebook.svg';
import iconInstagram from 'assets/img/icon-instagram.svg';
import iconMail from 'assets/img/icon-mail.svg';
import { withLoadApp } from 'hocs/with-load-app';

import ButtonChat from './components/ButtonChat/index';
import { HeaderProfileDashboard } from './components/HeaderProfileDashboard';
import { SideMenuDashboard } from './components/SideMenuDashboard';

import { MainContainer, Content, ContainerMidias, MidiaIcon } from './style';

export type DashBoardTemplateProps = {
  headerTitle: string;
  children: React.ReactNode;
  withSocial?: boolean;
  contentDirection?: 'center' | 'baseline';
  handleReturn?: () => void;
  isDashboard?: boolean;
};

export const DashBoardTemplate = withLoadApp(
  ({
    children,
    headerTitle,
    contentDirection = 'center',
    handleReturn,
    withSocial = true,
    isDashboard = false,
  }: DashBoardTemplateProps) => {
    const [openMenu, setMenu] = useState(false);
    const toggleSideMenu = () => setMenu(!openMenu);

    return (
      <MainContainer>
        <SideMenuDashboard
          openMenu={openMenu}
          toggleSideMenu={toggleSideMenu}
        />
        <HeaderProfileDashboard
          headerTitle={headerTitle}
          openMenu={openMenu}
          toggleSideMenu={toggleSideMenu}
          {...(handleReturn ? { handleReturn } : {})}
        />
        <Content contentDirection={contentDirection} isDashboard={isDashboard}>
          {children}

          {withSocial && (
            <ContainerMidias>
              <a
                href="https://pt-br.facebook.com/Noverde/"
                target="_blank"
                rel="noreferrer"
              >
                <MidiaIcon src={iconFacebook} />
              </a>
              <a
                href="https://www.instagram.com/noverdecredito"
                target="_blank"
                rel="noreferrer"
              >
                <MidiaIcon src={iconInstagram} />
              </a>
              <a
                href="mailto:meajuda@noverde.com.br"
                target="_blank"
                rel="noreferrer"
              >
                <MidiaIcon src={iconMail} />
              </a>
            </ContainerMidias>
          )}
        </Content>
        <ButtonChat />
      </MainContainer>
    );
  },
);
